<script setup>
  import { onMounted, ref, computed } from 'vue';

  // Updated props that uses object
  const props = defineProps({
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    labelWidth: {
      type: String,
      default: 'md',
    },
    fieldStyle: {
      type: String,
      default: '',
    },
    class: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
      required: false,
    },
  });

  defineEmits(['update:modelValue']);

  const select = ref(null);

  onMounted(() => {
    if (select.value && select.value.hasAttribute('autofocus')) {
      select.value.focus();
    }
  });

  const labelWidth = computed(() => {
    const labelWidth = props.labelWidth;
    if (labelWidth == 'sm') {
      return 'w-28';
    }
    if (labelWidth == 'xs') return 'w-20';

    if (labelWidth == 'lg') return 'w-48';

    return 'w-28 md:w-40';
  });

  const inputPaddingLeft = computed(() => {
    const labelWidth = props.labelWidth;
    if (labelWidth == 'sm') {
      return 'pl-36';
    }
    if (labelWidth == 'xs') return 'pl-28';

    return 'pl-36 md:pl-48';
  });
  const style = props.fieldStyle;
  const cl = props.class;
</script>

<template lang="">
  <div class="relative">
    <div
      v-if="true"
      :class="{
        cl: true,
      }">
      <label :class="`${labelWidth} absolute text-xs ml-3 mt-1.5 uppercase t-1 font-bold text-slate-400 truncate  pr-2 `">
        {{ placeholder }}
      </label>
      <select
        ref="input"
        :class="{
          'border border-gray-200 bg-white pt-6 pl-3 pb-2 text-sm rounded-md shadow-sm w-full': true,
        }"
        :value="modelValue"
        :disabled="props.disabled"
        @input="$emit('update:modelValue', $event.target.value)">
        <slot />
      </select>
      <div v-if="errorMessage" class="text-red-600 text-sm">{{ errorMessage }}</div>
    </div>
  </div>
</template>
