<script setup>
import { computed } from "vue";

const emit = defineEmits(["update:checked"]);

const props = defineProps({
  checked: {
    type: [Array, Boolean],
    default: false,
  },
  value: {
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const proxyChecked = computed({
  get() {
    return props.checked;
  },
  set(val) {
    if (!props.disabled) {
      emit("update:checked", val);
    }
  },
});
</script>

<template>
  <input
    type="checkbox"
    :value="value"
    v-model="proxyChecked"
    :disabled="disabled"
    :checked="checked"
    class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    :class="{ 'opacity-50 cursor-not-allowed bg-slate-200': disabled }"
  />
</template>
